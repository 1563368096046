/* eslint-disable no-tabs */
/* eslint-disable max-len */
export default [
  {
    title: 'オリオリモビ（ʻOliʻOli mobi）利用規約',
    description:
      'オリオリモビ（ʻOliʻOli mobi）利用規約（以下、「本規約」といいます。）は、JTB Hawaii Travel, LLC（以下、「当社」といいます。）が、主として、「mobi Community Mobility アプリ」（以下、「本アプリ」といいます。）を通じて運営・提供するオリオリモビ（ʻOliʻOli mobi）サービスの利用条件等を定めるものです。',
  },
  {
    title: '（適用範囲）',
    list1: [
      {
        title:
          '第１条	「本アプリ」を自ら保有又は管理するスマートフォン等の携帯型情報端末にインストールした者が、本アプリを利用して当社の提供するオリオリモビ（ʻOliʻOli mobi）サービスを利用する場合の運送契約は、この利用規約の定めるところにより、この利用規約に定めのない事項については、法令の定めるところ又は一般の慣習によります。',
      },
      {
        title:
          '本アプリを利用して当社の運行するオリオリモビ（ʻOliʻOli mobi）サービスを利用する者（以下、「利用者」といいます。申込する者も乗車する者も含みます。）は「mobi Community Mobilityアプリ利用規約」（以下、「原規約」といいます。）を全て確認の上、同意したものとみなします。',
      },
      {
        title: '当社は、原規約における運送サービス提供事業者となります。',
      },
      {
        title:
          '本規約の内容は、当社の判断において、以下の場合に利用者に対して何らの事前の通知なしに変更することがあります。この場合、変更日以降の利用については、変更後の本規約等の内容が適用されます。なお、当社は、本規約等の内容を変更する場合、合理的な期間をもって事前に、利用者に変更内容を通知するものとします。',
        list2: {
          content: [
            {title: '本規約の内容変更が、利用者の一般の利益のために実施されるとき'},
            {
              title:
                '本規約の内容変更が、本規約等の目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき。',
            },
          ],
        },
      },
      {
        title:
          '本規約等の定めの一部の効力が、法令又は判決により無効となった又は取り消された場合においても、残りの定めの有効性、適法性及び法的強制力はなお有効であるものとします。',
      },
    ],
  },
  {
    title: '（原規約の準用）',
    list1: [
      {
        title:
          '第2条	原規約第4条から第10条の定めは、本規約に準用します。準用された原規約における「当社」は、本規約に定めるJTB Hawaii Travel, LLCに読み替えるものとします。',
      },
    ],
  },
  {
    title: '（オリオリモビ（ʻOliʻOli mobi）サービスの内容・乗合サービス）',
    list1: [
      {
        title:
          '第3条　オリオリモビ（ʻOliʻOli mobi）サービスにおいて、利用者は、本アプリを使用して当社が設定する仮想乗降場所の中から乗車地及び降車地を選択し、その区間における乗合利用者自動車運送サービス（以下、「運送サービス」といいます。）の提供を受けることができます。',
      },
      {
        title:
          '前項に定める運送サービスは、利用者が仮想乗降場所の中から設定した乗車地から降車地までの区間の運送サービスを申し込む都度一回限りのサービスであり、以下「一回利用」といいます。',
      },
      {
        title:
          'オリオリモビ（ʻOliʻOli mobi）は、乗合旅客運送サービスです。ほぼ同一方角を走行する場合は、一部区間はあるいは全区間において他の利用者と乗り合わせる場合があります。',
      },
    ],
  },
  {
    title: '（利用者の責務）',
    list1: [
      {
        title:
          '第4条　利用者は、当社の運転者、その他の係員（以下、「乗務員等」といいます。）が運送の安全確保と車内秩序の維持のために行う職務上の指示に従わなければなりません。なお、利用者等が本条に違反する行為又は違反する恐れのある行為があったと合理的に乗務員等が認めた場合、かかる乗務員等は当該車両の管理者として、この利用者等に対し、直ちに乗車を拒み又は降車を命じることができるものとします。',
      },
      {
        title: '利用者等は、みだりに申し込みと取消を繰り返し行ってはならないものとします。',
      },
      {
        title: '当社は、利用者の故意若しくは過失により、又は利用者が法令若しくはこの利用規約の規定を守らないことにより当社が損害を受けたときは、その利用者に対し、その損害の賠償を求めます。',
      },
    ],
  },
  {
    title: '（運行エリア）',
    list1: [
      {
        title:
          '第5条	オリオリモビ（ʻOliʻOli mobi）は、本アプリに表示される運行エリア内が運送サービスの範囲であり、当該エリアを越える運送サービスはできません。',
      },
    ],
  },
  {
    title: '（乗降車の場所）',
    list1: [
      {
        title:
          '第6条	前第３条に定める仮想乗降場所を「Waypoint」といい、利用者は本アプリに表示されるWaypoint以外で乗車、又は降車できません。',
      },
      {
        title:
          '利用者は、本アプリにて事前に指定したWaypointから乗車し、同じく指定したWaypointにて降車します。指定したWaypoint以外やその他の場所での乗降車はできません。',
      },
      {
        title: '利用者は、乗車後に降車場所の変更はできません。',
      },
    ],
  },
  {
    title: '（オリオリカード提示による乗車）',
    list1: [
      {
        title: '第7条	利用者は、オリオリカードを乗務員等に提示しなければ乗車できません。',
      },
      {
        title:
          '利用者は、本アプリに設定した乗車人員を超えて乗車できません。',
      },
    ],
  },
  {
    title: '（車内持ち込み手荷物）',
    list1: [
      {
        title:
          '第8条	利用者は、自己の身の回り品1個のほか、次の各号に掲げる制限以内の手荷物1個を車内に持ち込むことができます。',
        note1: 'Ford バン（定員13名）',
        note2: 'Honda オデッセイ（定員6名）',
        list2: {
          content: [
            {
              title: '総重量10キログラム',
            },
            {
              title: 'サイズ80cm×30cm×50cm以内',
            },
          ],
        },
        list3: {
          content: [
            {
              title: '総重量10キログラム',
            },
            {
              title: 'サイズ100cm×30cm×50cm以内',
            },
          ],
        },
      },
      {
        title:
          '利用者は、前項の規定にかかわらず、制限以内の手荷物であっても、荷造りが必要と認められる場合に相当の荷造りがなされていないとき、その他運送に支障を及ぼし、又は他の利用者に迷惑を及ぼすおそれのあるときは、当該物品を車内に持ち込むことができません。',
      },
      {
        title: '利用者は、前1項の規定にかかわらず、第9条第７号の物品を車内に持ち込むことができません。',
      },
    ],
  },
  {
    title: '（運送の引受け及び継続の拒絶）',
    list1: [
      {
        title: '第9条	当社は、次の各号のいずれかに該当する場合には、運送の引受け又は継続を拒絶することがあります。',
        list2: {
          content: [
            {
              title: '当該運送の申込みがこの利用規約によらないものであるとき',
            },
            {
              title: '当該運送に適する設備がないとき',
            },
            {
              title: '当該運送に関し、利用者から特別な負担を求められたとき',
            },
            {
              title: '当該運送が法令の規定又は公の秩序若しくは善良の風俗に反するものであるとき',
            },
            {
              title: '天災その他やむを得ない事由による運送上の支障があるとき',
            },
            {
              title: '利用者が乗務員等の行う運送の安全確保と車内秩序の維持のための指示に従わないとき',
            },
            {
              title:
                '利用者が、爆発の恐れがあるもの、燃えやすいもの、人に危害を与えたり他の物件を損傷する恐れがあるものなど、凶器や危険物全般を携帯しているとき',
            },
            {
              title:
                '利用者が凶器や危険物全般を携帯しているおそれがあると認められ、乗務員等が携行品の明示を求めた場合で利用者がその求めに応じないとき',
            },
            {
              title:
                '利用者が泥酔した者又は不潔な服装をした者、保護者に伴われていない小児等であって、他の利用者の迷惑となるおそれのあるとき',
            },
            {
              title: '利用者が付添人を伴わない重病者であるとき',
            },
          ],
        },
      },
    ],
  },
  {
    title: '（携行品等に関する責任）',
    list1: [
      {
        title:
          '第10条	当社は、利用者の手荷物及び着衣、メガネ、時計その他の身の回り品について滅失又はき損によって生じた損害を賠償する責に任じません。ただし、当社又は当社の乗務員等がその滅失又はき損について過失があったときは、この限りでありません。',
      },
    ],
  },
  {
    title: '（異常気象時等における措置に関する責任）',
    list1: [
      {
        title:
          '第11条	当社は、天災その他当社の責に帰することができない事由により運行の安全の確保のため一時的に運行中止その他の措置をしたときは、これによって利用者が受けた損害を賠償する責に任じません。',
      },
    ],
  },
  {
    title: '（利用者等の情報）',
    list1: [
      {
        title:
          '第12条	利用者等は、オリオリモビ（ʻOliʻOli mobi）サービスの申し込みにより、当社がWILLERSから本アプリを通じて、運送サービスの提供を目的として、利用者等の氏名、性別、連絡先等の個人情報を必要な範囲において提供を受けること、及び当社及びWILLERSの定めるプライバシーポリシー又は個人情報取り扱い方針等を承諾したものとします。',
      },
      {
        title:
          '当社は、前項に基づき又はオリオリモビ（ʻOliʻOli mobi）サービス提供の過程で取得した利用者等の個人情報について、個人情報保護法と関連規定に基づき、適切に管理するものとします。',
      },
      {
        title:
          '前項に関して、当社及びWILLERSの定めるプライバシーポリシー又は個人情報取り扱い方針等並びに個人情報に関する問い合わせ先は、本アプリに掲載します。',
      },
    ],
  },
  {
    title: '（反社会的勢力の排除）',
    list1: [
      {
        title:
          '第13条	利用者等は、自らが以下の各号のいずれにも該当しないこと及び以下の各号の個人又は団体と密接な交流関係を有していないことを表明して、確約するものとします。',
        list2: {
          content: [
            {
              title: '暴力団',
            },
            {
              title: '暴力団の構成員（準構成員を含む。以下同じ。）',
            },
            {
              title:
                '暴力団関係企業又は暴力団若しくは暴力団の構成員が出資若しくは業務執行に関して重要な地位に就いている団体',
            },
            {
              title: '総会屋、社会運動標榜ゴロその他反社会的勢力に該当するもの',
            },
            {
              title: '特殊知能暴力集団その他反社会的勢力に該当する団体又はその構成員',
            },
            {
              title: 'その他前各号に準ずるもの',
            },
          ],
        },
      },
      {
        title:
          '利用者等は、自ら又は第三者を利用して、以下の各号に該当する行為を行わないことを表明して、確約するものとします。',
        list2: {
          content: [
            {
              title: '暴力的な又は法的な責任を超えた不当な要求行為',
            },
            {
              title: '取引に関して、脅迫的な言動をし、又は暴力を用いる行為',
            },
            {
              title:
                '風説を流布し、偽計を用い又は威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する行為',
            },
            {
              title: 'その他前各号に準ずる行為',
            },
          ],
        },
      },
      {
        title:
          '当社は、利用者等が前2項に定める表明事項に違反していた場合又は違反していると判断した場合、直ちにオリオリモビ（ʻOliʻOli mobi）サービスの提供を中止し、以後の提供を拒絶することができるとともに、これらの場合、当事者等は、自己の違反により、当社がこれにより被った全ての損害（風評被害等による逸失利益、弁護士費用等を含むがこれに限らない。）を賠償しなければならないものとします。',
      },
    ],
  },
  {
    title: '（準拠法）',
    list1: [
      {
        title: '第14条	本規約及び付随する規則等は、ハワイ法によって解釈されるものとします。',
      },
      {
        title:
          '本規約に関する紛争については、ハワイの民事訴訟により当社の登録住所を管轄する裁判所を第一審の独占的合意管轄裁判所とします。ただし、適用される強制法規により別の裁判所への提訴が許される場合は、その定めによります。',
      },
    ],
  },
];
